const countryArray = [
    { name: 'afghanistan af' },
    { name: 'albania al' },
    { name: 'algeria dz' },
    { name: 'andorra ad' },
    { name: 'angola ao' },
    { name: 'antigua and barbuda ag' },
    { name: 'argentina ar' },
    { name: 'armenia am' },
    { name: 'australia au' },
    { name: 'austria at' },
    { name: 'azerbaijan az' },
    { name: 'bahamas bs' },
    { name: 'bahrain bh' },
    { name: 'bangladesh bd' },
    { name: 'barbados bb' },
    { name: 'belarus by' },
    { name: 'belgium be' },
    { name: 'belize bz' },
    { name: 'benin bj' },
    { name: 'bhutan bt' },
    { name: 'bolivia bo' },
    { name: 'bosnia and herzegovina ba' },
    { name: 'botswana bw' },
    { name: 'brazil br' },
    { name: 'brunei bn' },
    { name: 'bulgaria bg' },
    { name: 'burkina faso bf' },
    { name: 'burundi bi' },
    { name: 'cabo verde cv' },
    { name: 'cambodia kh' },
    { name: 'cameroon cm' },
    { name: 'canada ca' },
    { name: 'central african republic cf' },
    { name: 'chad td' },
    { name: 'chile cl' },
    { name: 'china cn' },
    { name: 'colombia co' },
    { name: 'comoros km' },
    { name: 'congo cg' },
    { name: 'costa rica cr' },
    { name: 'croatia hr' },
    { name: 'cuba cu' },
    { name: 'cyprus cy' },
    { name: 'czech republic cz' },
    { name: 'denmark dk' },
    { name: 'djibouti dj' },
    { name: 'dominica dm' },
    { name: 'dominican republic do' },
    { name: 'east timor tl' },
    { name: 'ecuador ec' },
    { name: 'egypt eg' },
    { name: 'el salvador sv' },
    { name: 'equatorial guinea gq' },
    { name: 'eritrea er' },
    { name: 'estonia ee' },
    { name: 'eswatini sz' },
    { name: 'ethiopia et' },
    { name: 'fiji fj' },
    { name: 'finland fi' },
    { name: 'france fr' },
    { name: 'gabon ga' },
    { name: 'gambia gm' },
    { name: 'georgia ge' },
    { name: 'germany de' },
    { name: 'ghana gh' },
    { name: 'greece gr' },
    { name: 'grenada gd' },
    { name: 'guatemala gt' },
    { name: 'guinea gn' },
    { name: 'guinea-bissau gw' },
    { name: 'guyana gy' },
    { name: 'haiti ht' },
    { name: 'honduras hn' },
    { name: 'hungary hu' },
    { name: 'iceland is' },
    { name: 'india in' },
    { name: 'indonesia id' },
    { name: 'iran ir' },
    { name: 'iraq iq' },
    { name: 'ireland ie' },
    { name: 'israel il' },
    { name: 'italy it' },
    { name: 'ivory coast ci' },
    { name: 'jamaica jm' },
    { name: 'japan jp' },
    { name: 'jordan jo' },
    { name: 'kazakhstan kz' },
    { name: 'kenya ke' },
    { name: 'kiribati ki' },
    { name: 'kosovo xk' },
    { name: 'kuwait kw' },
    { name: 'kyrgyzstan kg' },
    { name: 'laos la' },
    { name: 'latvia lv' },
    { name: 'lebanon lb' },
    { name: 'lesotho ls' },
    { name: 'liberia lr' },
    { name: 'libya ly' },
    { name: 'liechtenstein li' },
    { name: 'lithuania lt' },
    { name: 'luxembourg lu' },
    { name: 'madagascar mg' },
    { name: 'malawi mw' },
    { name: 'malaysia my' },
    { name: 'maldives mv' },
    { name: 'mali ml' },
    { name: 'malta mt' },
    { name: 'marshall islands mh' },
    { name: 'mauritania mr' },
    { name: 'mauritius mu' },
    { name: 'mexico mx' },
    { name: 'micronesia fm' },
    { name: 'moldova md' },
    { name: 'monaco mc' },
    { name: 'mongolia mn' },
    { name: 'montenegro me' },
    { name: 'morocco ma' },
    { name: 'mozambique mz' },
    { name: 'myanmar mm' },
    { name: 'namibia na' },
    { name: 'nauru nr' },
    { name: 'nepal np' },
    { name: 'netherlands nl' },
    { name: 'new zealand nz' },
    { name: 'nicaragua ni' },
    { name: 'niger ne' },
    { name: 'nigeria ng' },
    { name: 'north macedonia mk' },
    { name: 'norway no' },
    { name: 'oman om' },
    { name: 'pakistan pk' },
    { name: 'palau pw' },
    { name: 'panama pa' },
    { name: 'papua new guinea pg' },
    { name: 'paraguay py' },
    { name: 'peru pe' },
    { name: 'philippines ph' },
    { name: 'poland pl' },
    { name: 'portugal pt' },
    { name: 'qatar qa' },
    { name: 'romania ro' },
    { name: 'russia ru' },
    { name: 'rwanda rw' },
    { name: 'saint kitts and nevis kn' },
    { name: 'saint lucia lc' },
    { name: 'saint vincent and the grenadines vc' },
    { name: 'samoa ws' },
    { name: 'san marino sm' },
    { name: 'sao tome and principe st' },
    { name: 'saudi arabia sa' },
    { name: 'senegal sn' },
    { name: 'serbia rs' },
    { name: 'seychelles sc' },
    { name: 'sierra leone sl' },
    { name: 'singapore sg' },
    { name: 'slovakia sk' },
    { name: 'slovenia si' },
    { name: 'solomon islands sb' },
    { name: 'somalia so' },
    { name: 'south africa za' },
    { name: 'south korea kr' },
    { name: 'south sudan ss' },
    { name: 'spain es' },
    { name: 'sri lanka lk' },
    { name: 'sudan sd' },
    { name: 'suriname sr' },
    { name: 'sweden se' },
    { name: 'switzerland ch' },
    { name: 'syria sy' },
    { name: 'taiwan tw' },
    { name: 'tajikistan tj' },
    { name: 'tanzania tz' },
    { name: 'thailand th' },
    { name: 'togo tg' },
    { name: 'tonga to' },
    { name: 'trinidad and tobago tt' },
    { name: 'tunisia tn' },
    { name: 'turkey tr' },
    { name: 'turkmenistan tm' },
    { name: 'tuvalu tv' },
    { name: 'uganda ug' },
    { name: 'ukraine ua' },
    { name: 'united arab emirates ae' },
    { name: 'united kingdom gb' },
    { name: 'united states us' },
    { name: 'uruguay uy' },
    { name: 'uzbekistan uz' },
    { name: 'vanuatu vu' },
    { name: 'vatican city va' },
    { name: 'venezuela ve' },
    { name: 'vietnam vn' },
    { name: 'yemen ye' },
    { name: 'zambia zm' },
    { name: 'zimbabwe zw' },
];

export default countryArray;
