export const currency = [
    { name: 'usd' },
    { name: 'eur' },
    { name: 'gbp' },
    { name: 'bdt' },
    { name: 'jpy' },
    { name: 'aud' },
    { name: 'cad' },
    { name: 'chf' },
    { name: 'cny' },
    { name: 'sgd' },
    { name: 'hkd' },
    { name: 'sek' },
    { name: 'nzd' },
    { name: 'krw' },
    { name: 'inr' },
    { name: 'brl' },
    { name: 'zar' },
    { name: 'mxn' },
    { name: 'try' },
    { name: 'rub' },
    { name: 'aed' },
    { name: 'huf' },
    { name: 'pln' },
    { name: 'thb' },
    { name: 'dkk' },
    { name: 'myr' },
    { name: 'nok' },
    { name: 'czk' },
    { name: 'ils' },
    { name: 'clp' },
    { name: 'sar' },
    { name: 'zar' },
    { name: 'sgd' },
    { name: 'aed' },
    { name: 'hkd' },
    { name: 'pen' },
    { name: 'qar' },
    { name: 'cop' },
    { name: 'sek' },
    { name: 'ngn' },
    { name: 'idr' },
    { name: 'egp' },
    { name: 'kwd' },
    { name: 'ars' },
    { name: 'vnd' },
    { name: 'twd' },
    { name: 'uah' },
    { name: 'clp' },
    { name: 'php' },
    { name: 'cop' },
    { name: 'rsd' },
    { name: 'ron' },
    { name: 'cve' },
    { name: 'xof' },
    { name: 'other' },
];
